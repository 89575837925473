import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import { BenchmarkDetail as BD } from '../../components/benchmarksui';
import { LINKED_LIST } from '../../types/software';
import { LinkedListCharts } from '../../components/benchmarks/linkedlist/linkedlist';

import {
    labelColor,
    activeDot,
    dot,
    strokeWidth
} from '../../chartstyles';

const LinkedListBenchmark = () => {
    return (
        <BD item={LINKED_LIST} title="Performance: deepmesa::LinkedList vs std::LinkedList">
            <p>The deepmesa linked list was designed to be fast. Here we demonstrate the performance gains against std::LinkedList. </p>

            <BD.SubHeader text="push_front() is 2x faster" divider />
            <p>The Deepmesa Linkedlist is 2x faster at pushing elements to the front of the list. This is primarily because the list doesn't have to allocate memory on every push</p>
            <LinkedListCharts
                group="push"
                dir="front"
                pdfXRangeMap={{
                    "std:push_front()": {
                        min: 36.25,
                        max: 37
                    },
                    "dm:push_front()": {
                        min: 17,
                        max: 20
                    }
                }}
            />

            <BD.SubHeader text="push_back() is 2x faster" divider />
            <p>Similarly, the Deepmesa Linkedlist is 2x faster at pushing elements to the back of the list. Again, this is because the list doesn't have to allocate memory on every push</p>
            <LinkedListCharts group="push" dir="back"
                pdfXRangeMap={{
                    "std:push_back()": {
                        min: 36.5,
                        max: 38
                    },
                    "dm:push_back()": {
                        min: 17,
                        max: 19.8
                    }
                }}

            />

            <BD.SubHeader text="pop_front() is more than 2x faster" divider />
            <p>The Deepmesa Linkedlist is 2x faster at popping elements from the front of the list. This is primarily because the list doesn't have to deallocate memory on every pop</p>
            <LinkedListCharts group="pop" dir="front"
                pdfXRangeMap={{
                    "std:pop_front()": {
                        min: 15.5,
                        max: 23.5
                    },
                    "dm:pop_front()": {
                        min: 7.5,
                        max: 11
                    }
                }}

            />

            <BD.SubHeader text="pop_back() is more than 2x faster" divider />
            <p>The Deepmesa Linkedlist is 2x faster at popping elements from the back of the list. This is because the list doesn't have to deallocate memory on every pop</p>
            <LinkedListCharts group="pop" dir="back"
                pdfXRangeMap={{
                    "std:pop_back()": {
                        min: 15,
                        max: 23.8
                    },
                    "dm:pop_back()": {
                        min: 7.5,
                        max: 11
                    }
                }}
            />
            <BD.SubHeader text="push_middle() is 35% faster than std:push_front()" divider />
            <p>std::LinkedList doesn't allow pushing in the middle of the list so we benchmark against pushing to the front of the list. Pushing in the middle of the deepmesa list is a faster constant time operation than pushing to the front of the std::LinkedList.</p>
            <LinkedListCharts group="push"
                dmSeries="dm:push_middle()"
                stdSeries="std:push_front()"
                timeXRange={{
                    min: 8400,
                    max: 272000
                }}
                pdfXRangeMap={{
                    "std:push_front()": {
                        min: 36.2,
                        max: 37
                    },
                    "dm:push_middle()": {
                        min: 18.9,
                        max: 27
                    }
                }}
            />
        </BD>
    );
}

export default LinkedListBenchmark;
